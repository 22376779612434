import React from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';
import Member from './Member';

const Description = styled('p')``;
const Members = styled('div')``;
const Title = styled('h1')``;
const Wrapper = styled('div')``;

const members = [
  {
    avatar:
      'https://res-3.cloudinary.com/crunchbase-production/image/upload/c_thumb,h_256,w_256,f_auto,g_faces,z_0.7,q_auto:eco/v1501498574/nkln2sn1ilfg28d2agoc.png',
    information: [
      { key: 1, value: 'Stanford PhD EE' },
      { key: 2, value: 'Serial entrepreneur' },
      { key: 3, value: 'Expert in AI/ML' },
      { key: 4, value: 'Enterprise software product development' },
    ],
    key: 1,
    link: 'https://www.linkedin.com/in/arashhassibi',
    name: 'Arash Hassibi',
    position: 'CEO',
  },
  {
    avatar:
      'https://cdn.ingenico.com/binaries/content/gallery/blog/experts/michel-leger.jpg',
    information: [
      { key: 1, value: 'EVP Global Solutions' },
      { key: 2, value: 'WorldLine' },
    ],
    key: 2,
    link: 'https://www.linkedin.com/in/michel-leger-b7ab1534',
    name: 'Michel Leger',
  },
  {
    avatar:
      'https://global-uploads.webflow.com/5ef788f07804fb7d78a4127a/5efa4b56de5d066ed7f38638_5ef1d6c1dc350b9fdeaff56e_Dennis%2520Wakabayshi.jpeg',
    information: [
      { key: 1, value: 'Top CX influencer of the decade' },
      { key: 2, value: 'VP of RR Donnelley' },
      { key: 3, value: 'Expert integrated marketing' },
    ],
    key: 3,
    link: 'https://www.linkedin.com/in/denniswakabayashi',
    name: 'Dennis Wakabayashi',
  },
  {
    avatar:
      'https://static.wixstatic.com/media/4181c5_88793bf48bdf4685bfc8196416625b7f~mv2.jpg/v1/crop/x_5,y_0,w_882,h_882/fill/w_544,h_544,al_c,q_80,usm_0.66_1.00_0.01/tirto_2019_may_image.webp',
    information: [
      { key: 1, value: 'General Partner' },
      { key: 2, value: 'Palapa Ventures' },
    ],
    key: 4,
    link: 'https://www.linkedin.com/in/tirtoadji',
    name: 'Tirto Adji',
  },
];

const Team = ({ className }) => {
  return (
    <div className={className}>
      <Title>Leadership Team</Title>
      <Description>
        Joinedapp’s top tier team from finance, software and customer experience
        industries have a proven track record of delivering start-up growth and
        scaled shareholder value.
      </Description>
      <Wrapper>
        <Members>
          {members.map(member => (
            <Member key={member.key} {...member} />
          ))}
        </Members>
      </Wrapper>
    </div>
  );
};

Team.propTypes = {
  className: PropTypes.string,
};

export default styled(Team)`
  background-color: #003566;
  margin-bottom: 4rem;
  padding: 0 0 4rem;

  ${Description} {
    color: #fff;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
    margin: 0 3.2rem;
    max-width: 100rem;
    text-align: center;
  }

  ${Members} {
    margin: 0 auto;
    max-width: 114rem;
    padding: 2.4rem 0 1.6rem;
    text-align: center;
  }

  ${Title} {
    color: #fff;
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 4.8rem;
    padding: 2.4rem 0 0.8rem;
    text-align: center;
  }

  @media screen and (min-width: 600px) {
    ${Description} {
      font-size: 2.2rem;
      line-height: 2.4rem;
      margin: 0 auto;
      padding: 0 5rem;
    }

    ${Members} {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      padding: 6.4rem 0 0;
    }

    ${Title} {
      font-size: 4.8rem;
      line-height: 4.8rem;
      padding: 4rem 0 3.2rem;
    }
  }
`;
