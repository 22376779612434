import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

const Avatar = styled('img')``;
const AvatarWrapper = styled('div')``;
const Information = styled('div')``;
const LinkedInIcon = styled('img')``;
const Name = styled('p')``;
const Position = styled('p')``;

const Member = ({ avatar, className, link, name, position, information }) => {
  const [hover, setHover] = useState(false);
  const ref = useRef(null);

  const onClick = event => {
    event.preventDefault();

    if (window.screen.width < 600) {
      setHover(true);
    } else {
      window.open(link, '_blanc').focus();
    }
  };

  const onClickIcon = event => {
    event.preventDefault();
    event.stopPropagation();

    window.open(link, '_blanc').focus();
  };

  const onKeyDown = event => {
    if (event.keyCode === 13) {
      onClick(event);
    }
  };

  const handleClickOutside = event => {
    if (hover && ref && !ref.current.contains(event.target)) {
      setHover(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <div
      className={`${className} ${hover ? 'hover' : ''}`}
      onClick={onClick}
      onKeyDown={onKeyDown}
      ref={ref}
      role="button"
      tabIndex={0}
    >
      <AvatarWrapper>
        <Avatar alt={name} src={avatar} />
      </AvatarWrapper>
      <Name>{name}</Name>
      <Position>{position}</Position>
      <Information>
        {information.map(el => (
          <p key={el.key}>{el.value}</p>
        ))}
      </Information>
      <LinkedInIcon alt="LinkedIn" onClick={onClickIcon} src="/linkedIn.png" />
    </div>
  );
};

Member.defaultProps = {
  information: [],
};

Member.propTypes = {
  avatar: PropTypes.string,
  className: PropTypes.string,
  information: PropTypes.array,
  link: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.string,
};

export default styled(Member)`
  cursor: pointer;
  margin: 0 auto 3.2rem;
  position: relative;
  width: 24rem;

  ${Avatar} {
    max-width: 100%;
    max-height: 100%;
    transition: all 200ms linear;
  }

  ${AvatarWrapper} {
    height: 24rem;
    margin: 0 auto 1.6rem;
    text-align: center;
    width: 24rem;
  }

  ${Information} {
    align-items: center;
    background-color: transparent;
    color: transparent;
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    font-weight: 400;
    height: 24rem;
    justify-content: space-evenly;
    left: calc(50% - 12rem);
    line-height: 1.8rem;
    padding: 2rem;
    position: absolute;
    top: 0;
    transition: all 200ms linear;
    width: 24rem;
  }

  ${LinkedInIcon} {
    cursor: pointer;
    display: none;
    position: absolute;
    right: calc(50% - 12rem + 1rem);
    top: 1rem;
    width: 2.5rem;
  }

  ${Name}, ${Position} {
    color: #fff;
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 2.4rem;
  }

  &:focus {
    outline: none;
  }

  &:hover,
  &.hover {
    ${Avatar} {
      filter: grayscale(100%);
    }

    ${Information} {
      background-color: rgba(0, 53, 102, 0.5);
      color: #fff;
    }
  }

  &.hover {
    ${LinkedInIcon} {
      display: block;
    }
  }

  @media screen and (min-width: 600px) {
    margin: 0 7rem 3.2rem;
    width: 24rem;
  }
`;
